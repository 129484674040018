<template>
    <div class="container-fluid core-view d-flex flex-column">
        <LList
            analytics-prefix="Data Maps"
            :columns="columns"
            :companies="[]"
            :items="dataMaps"
            model-name="data maps"
            :total-item-count="totalMapCount"
            item-component="LListItem"
            :loading="loading"
            :fetching="fetching"
            :tooltip-wording="tooltipWording"
            @fetch="fetch"
        >
            <template
                v-if="canManage"
                slot="action-buttons"
            >
                <RouterLink
                    :to="{name: 'admin.datamaps.add'}"
                    aria-label="Add Data Map"
                    data-cy="addDataMapBtn"
                    class="btn btn-primary"
                >
                    <FontAwesomeIcon
                        class="mr-1"
                        icon="plus"
                    />
                    Data Map
                </RouterLink>
            </template>

            <template #list-item-action-buttons="{ item }">
                <RouterLink
                    v-b-tooltip.hover.left
                    data-cy="editDataMapBtn"
                    :to="{name: 'admin.datamaps.edit', params: {id: item.id}}"
                    class="btn btn-link btn-options"
                    title="Data Map Options"
                    aria-label="Data Map Options"
                >
                    <FontAwesomeIcon
                        icon="cog"
                        class="fas fa-2x fa-fw"
                    />
                </RouterLink>
            </template>
        </LList>

        <RouterView />
    </div>
</template>

<script>
    import LList from '@imt/vue-list/src/components/LList.vue';
    import LStatusIndicator from '@imt/vue-list/src/components/LStatusIndicator.vue';
    import dateMixin from '@imt/vue-toolbox/src/mixins/date';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import VersionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex';

    import authMixin from '../mixins/auth';

    export default {
        name: 'MDataMapList',
        components: {
            LList,
        },
        mixins: [
            authMixin,
            dateMixin,
            toastsMixin,
            VersionMixin,
        ],
        metaInfo: {
            title: 'Data Maps List',
        },
        data() {
            return {
                loading: true,
                fetching: true,
                tooltipWording: {
                    search_field_display: {},
                    search_field_names: {
                        text: 'Text Search',
                        title: 'Title',
                        search: 'Text search within Title and Code',
                        updated_by: 'Modified By',
                    },
                },
                columns: [
                    {
                        key: 'title',
                        class: 'col-6 col-md-3 text-break',
                        value: item => {
                            return this.sortVersions(item)[0].title;
                        },
                        sortable: true,
                        sortBy: 'versions__title',
                    },
                    {
                        key: 'code',
                        class: 'col-4 col-lg-2 text-break d-none d-md-inline',
                        value: i => i.code,
                        sortable: true,
                        sortBy: 'code',
                    },
                    {
                        key: 'policySystem',
                        class: 'col-2 text-break d-none d-lg-inline',
                        value: item => {
                            return item.policySystemId
                                ? this.formattedPolicySystems?.filter(ps => ps.id === item.policySystemId.toString())[0]?.title
                                : 'All';
                        },
                        sortable: false,
                    },
                    {
                        key: 'modified',
                        class: 'col-2 d-none d-lg-inline',
                        value: item => {
                            const version = this.sortVersions(item)[0];

                            return `${this.dateFormatted(version.updatedAt)} by ${version.updatedBy.username}`;
                        },
                        sortable: true,
                        sortBy: 'updated_at',
                    },
                    {
                        key: 'status',
                        class: 'col-1 d-none d-sm-inline page-status',
                        component: LStatusIndicator,
                        sortable: false,
                        options: {
                            disableConflicts: true,
                            wording: {
                                not_published: 'Not yet published',
                                published: 'Published',
                                scheduled: 'Scheduled to be published',
                            },
                            statusFrom: item => {
                                return this.sortVersions(item)[0];
                            },
                        },
                    },
                    {
                        key: 'options',
                        class: 'col-auto options px-0 ml-auto',
                    },
                ],
            };
        },
        computed: {
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
            ...mapState('mapping', [
                'dataMaps',
                'totalMapCount'
            ]),
            ...mapState('toolbox', [
                'sort',
                'permissions',
            ]),
        },
        async created() {
            await this.fetchPolicySystems();
            this.loading = false;
        },
        methods: {
            async fetch(query = this.$route.query) {
                await this.fetchDataMaps({queryObj: query});
                this.fetching = false;
            },
            ...mapActions('mapping', ['fetchDataMaps']),
            ...mapActions('toolbox', [
                'fetchPolicySystems',
            ]),
        },
    };
</script>
